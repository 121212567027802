/* src/assets/styles/ProcurementApplication.css */

.procurement-application-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: #f7f9fc;
    border-radius: 8px;
}

.procurement-application-card {
    border: none;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
}

.procurement-application-card h2 {
    font-size: 1.75rem;
    font-weight: bold;
}

.procurement-application-card .text-muted {
    font-size: 0.9rem;
    margin-bottom: 20px;
}

.terms-checkbox {
    font-size: 0.95rem;
    font-weight: 500;
    color: #333;
}

.submit-button {
    background-color: #007bff;
    border-color: #007bff;
    padding: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 8px;
}

.submit-button:hover {
    background-color: #0056b3;
    border-color: #004085;
}

.submit-button:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}