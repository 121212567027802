/* src/assets/styles/Profile.css */

.profile-container {
    margin-top: 20px;
}

.card-header {
    font-size: 1.5rem;
    font-weight: bold;
}

.card-title {
    margin-bottom: 20px;
    font-size: 1.25rem;
}

.list-group-item {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 15px;
}

.list-group-item strong {
    margin-bottom: 5px;
}

.icon {
    margin-right: 8px;
}

.nav-tabs .nav-link {
    font-size: 1rem;
    font-weight: bold;
}

.nav-tabs .nav-link.active {
    background-color: #28a745;
    color: white;
}