/* src/assets/styles/NavigationBar.css */

.navbar-logo {
    height: 40px;
  }
  
  .navbar-nav .nav-item {
    margin-left: 1rem;
  }
  
  .navbar-nav .nav-link {
    font-weight: 500;
    color: #333;
  }
  
  .navbar-nav .nav-link.active {
    color: #0d6efd; /* Bootstrap primary color */
  }
  
  .navbar-nav .nav-link:hover {
    color: #0056b3;
  }
  
  .navbar-nav .btn {
    font-weight: 500;
  }
  
  .navbar-light .navbar-toggler {
    border-color: rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 991px) {
    .navbar-nav .nav-item {
      margin-left: 0;
      margin-bottom: 0.5rem;
    }
  }
  